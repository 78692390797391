import { EventAggregator  } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { I18n } from 'common/i18n';
import { UiWidgets } from 'services/ui-widgets';
import { Tasks } from 'services/tasks';
import { Messages } from 'services/messages';
import { Menus } from 'services/menus';
import { Carriers } from 'services/carriers';
import { MemberConfig } from 'services/member-config';
import { Dashboards } from 'services/dashboards';
import { Config } from 'services/config';
import { Api } from 'common/server';
import { AgentNumbers } from 'services/agent-numbers';
import { ToDos } from 'services/to-dos';
import { c } from 'common/common';

export class Bootstrapper {
    static inject = [EventAggregator, Security, I18n, UiWidgets, Tasks, Messages, Menus, Carriers, MemberConfig, Dashboards, Config, Api, AgentNumbers, ToDos];
    _ea;
    _security;
    _i18n;
    _uiWidgets;
    _tasks;
    _messages;
    _menus;
    _carriers;
    _memberConfig;
    _dashboards;
    _config;
    _api;
    _agentNumbers;
    _toDos;

    _handlers = [];

    constructor(ea, security, i18n, uiWidgets, tasks, messages, menus, carriers, memberConfig, dashboards, config, api, agentNumbers, toDos) {
        this._ea = ea;
        this._security = security;
        this._i18n = i18n;
        this._uiWidgets = uiWidgets;
        this._tasks = tasks;
        this._messages = messages;
        this._menus = menus;
        this._carriers = carriers;
        this._memberConfig = memberConfig;
        this._dashboards = dashboards;
        this._config = config;
        this._api = api;
        this._agentNumbers = agentNumbers;
        this._toDos = toDos;

        this._handlers.push(this._ea.subscribe(c.EventKeys.loginAuthenticationUpdated, () => {
            if (this._security.isAuthenticated) return;
            this._handleLogout();
        }));
    }

    async forApp() {
        try {
            await this._security.loadState();
            const initializers = [
                this._config.initialize(),
                this._i18n.initialize(),
                this._uiWidgets.initialize(),
                this._api.initialize(),
            ];
            await Promise.all(initializers);
        } catch (err) {
            console.log(err);
        }
    }

    async forLogin() {
        try {
            const initializers = [
                this._memberConfig.initialize(),
                this._dashboards.initialize(),
            ];
            await Promise.all(initializers);
            await this._menus.initialize();
        } catch (err) {
            console.log(err);
        }
    }

    /**
     * Process any of the API calls that can complete AFTER login in the background
     */
    forLoginBackground() {
        window.setTimeout(() => this._forLoginBackground(), 2500);
    }

    async _forLoginBackground() {
        try {
            const initializers = [
                this._messages.initialize(),
                this._tasks.initialize(),
                this._toDos.initialize(),
            ];
            await Promise.all(initializers);
        } catch (err) {
            console.log(err);
        }
    }

    _handleLogout() {
        this._tasks.dispose();
        this._messages.dispose();
        this._menus.dispose()
        this._carriers.dispose();
        this._memberConfig.dispose();
        this._dashboards.dispose();
        this._agentNumbers.dispose();
        this._toDos.dispose();
    }
}
