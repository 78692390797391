import { inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { HttpClient } from 'aurelia-http-client';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Notifier } from 'common/ui';
import { c, apiUrlBase } from 'common/common';

@inject(HttpClient, Router, EventAggregator, Security, Notifier)
export class Api {
    _ea;

    authHeader = '';
	baseUrl = '';
	baseApiUrl = '';
	isDevelopment = false;
	hasSecurityNotice = false;
    _additionalHeaders = [];

	constructor(http, router, ea, security, notifier) {
		this.baseUrl = apiUrlBase();
		this.baseApiUrl = this.baseUrl + "/api/";
		this.isDevelopment = this.baseUrl.indexOf("localhost") >= 0;

        this.security = security;
		this.router = router;
        this._ea = ea;
		this.notifier = notifier;
		//this.http = http;
		this.authHeader = "Authorization";
		var d = new Date();
		var timezoneOffset = d.getTimezoneOffset();
        const me = this;
		this.http = http.configure(x => {
			x.withHeader("Accept", "application/json");
			x.withHeader("Content-Type", "application/json");
			x.withHeader("X-LEGACY-TimezoneOffset", timezoneOffset);
            x.withInterceptor({
                request(message) {
                    me._additionalHeaders.forEach(h => {
                        message.headers.add(h.key, h.value);
                    });
                    return message;
                },
            });
		});
	}

    async initialize() {
        // Don't await, don't want to hold up; the UI display just to get the IP address
        this._loadIp();
    }

    async _loadIp() {
        try {
            const httpClient = new HttpClient();
            const response = await httpClient.get('https://api.ipify.org?format=json');
            var data = JSON.parse(response.response);
            if (data.ip) this.addHeader('X-LEGACY-ip', data.ip);
        } catch (err) {
            console.log(err);
        }
    }

	authHeaderValue() {
		return "Bearer " + this.security.token;
	}

	shadowHeaderValue() {
        return this.security.shadowUserId;
    }

    addHeader(key, value) {
        this._additionalHeaders.push({ key, value });
    }

    clearHeaders() {
        this._additionalHeaders = [];
    }

	resetSecurityCheck() {
		this.hasSecurityNotice = false;
	}

    post(api, payload) {
        return this.postData(api, payload);
    }

	postData(api, payload) {
		var me = this;
		return new Promise((resolve, reject) => {
			this.http.createRequest(this.getFullUrl(api))
				.asPost()
				.withContent(payload)
				.withHeader(me.authHeader, me.authHeaderValue())
                .withHeader('X-LEGACY-ShadowUserId', me.shadowHeaderValue())
				.send().then(httpResponse => {
					if (!(httpResponse.statusCode === 200 || httpResponse.statusCode === 201)) { // OK, Created
						reject(httpResponse.statusText);
						return;
					}
					var data;
					if (httpResponse.response != null && httpResponse.response != "") data = JSON.parse(httpResponse.response);
					resolve(data);
				}).catch(error => {
					if (error.response === "") {
						reject("Error"); 
						return;
					}
					var httpResponse = JSON.parse(error.response);
					reject(httpResponse.message);
				});
		});
	}

    put(api, payload) {
        return this.updateData(api, payload);
    }

	updateData(api, payload) {
		var me = this;
		return new Promise((resolve, reject) => {
			me.http.createRequest(me.getFullUrl(api))
				.asPut()
				.withContent(payload)
				.withHeader(me.authHeader, me.authHeaderValue())
                .withHeader('X-LEGACY-ShadowUserId', me.shadowHeaderValue())
				.send().then(httpResponse => {
					if (!(httpResponse.statusCode === 200)) {
						reject(httpResponse.statusText);
						return;
					}
					var data = JSON.parse(httpResponse.response);
					resolve(data);
				}).catch(error => {
					var httpResponse = JSON.parse(error.response);
					reject(httpResponse.message);
				});
		});
	}

    get(api) {
        return this.getData(api);
    }

	getData(api) {
	    var me = this;
	    var ms = (new Date).getTime();
	    if (api.indexOf('?') >= 0) api += '&no-cache=' + ms; else api += '?no-cache=' + ms;
	    return new Promise((resolve, reject) => {

	        if (me.security.isAuthenticated) {
	            me.http.createRequest(me.getFullUrl(api))
                    .asGet()
                    .withHeader(me.authHeader, me.authHeaderValue())
					.withHeader('X-LEGACY-ShadowUserId', me.shadowHeaderValue())
                    .send().then(httpResponse => {
                        if (httpResponse.statusCode !== 200) {
                            reject(httpResponse.statusText);
                            return;
                        }
                        var data = JSON.parse(httpResponse.response);
                        resolve(data);
                    }).catch(error => {
                        if (error.statusCode === 401) {
                            if (me.hasSecurityNotice) {
                                reject("Unauthorized");
                                return;
                            }
                            me.notifier.error('login-sign-in-for-security-message');
                            me.hasSecurityNotice = true;
                            me._ea.publish(c.EventKeys.login.timedOut);
                            return this.router.navigate('logout', true);
                        }
                        reject("An error occurred");
                    });
	        } else {
	            me.http.createRequest(me.getFullUrl(api))
                    .asGet()
                    .send().then(httpResponse => {
                        if (httpResponse.statusCode !== 200) {
                            reject(httpResponse.statusText);
                            return;
                        }
                        var data = JSON.parse(httpResponse.response);
                        resolve(data);
                    }).catch(error => {
                        if (error.statusCode === 401) {
                            if (me.hasSecurityNotice) {
                                reject("Unauthorized");
                                return;
                            }
                            me.notifier.error('login-sign-in-for-security-message');
                            me.hasSecurityNotice = true;
                            me._ea.publish(c.EventKeys.login.timedOut);
                            return this.router.navigate('logout', true);
                        }
                        reject("An error occurred");
                    });
	        }
	    });
	}

    delete(api, payload) {
        return this.deleteData(api, payload);
    }

    deleteData(api, payload) {
		var me = this;
		return new Promise((resolve, reject) => {
			me.http.createRequest(me.getFullUrl(api))
				.asDelete()
                .withContent(payload)
				.withHeader(me.authHeader, me.authHeaderValue())
                .withHeader('X-LEGACY-ShadowUserId', me.shadowHeaderValue())
				.send().then(httpResponse => {
					if (httpResponse.statusCode !== 200) {
						reject(httpResponse.statusText);
						return;
					}
                    if (httpResponse.response) {
                        try {
                            var data = JSON.parse(httpResponse.response);
                            resolve(data);
                        } catch (dataErr) {
                            console.log(dataErr);
                            resolve(true);
                        }
                    }
					resolve(true);
				}).catch(error => {
					var message = "An error occurred";
					if (error.response) {
						var data = JSON.parse(error.response);
						if (data) message = data.message;
					}
					reject(message);
				});
		});
	}

	getFullUrl(api) {
		if (api.indexOf('http') != 0) api = this.baseApiUrl + api;
		return api;
	}

}
