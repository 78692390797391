import { DialogController } from 'aurelia-dialog';

export class ConfirmDialog {
    static inject = [Element, DialogController];
    _element;
    dialogController;

    inputIsNumber = false;
    inputLabel;
    validateHeaderValue;
    key;

    constructor(element, dialogController) {
        this._element = element;
        this.dialogController = dialogController;
    }

    activate(model) {
        this.key = model.key;
        this.messageObject = model.messageObject;
        this.okButtonClass = model.okButtonClass;
        this.showInput = model.showInput;
        this.inputLabel = model.inputLabel;
        this.value = model.inputValue || undefined;

        if (model.inputNumber) {
            this.inputIsNumber = true;
            this.minNumber = model.inputNumber.min;
            this.maxNumber = model.inputNumber.max;
            this.numberStep = model.inputNumber.step;
        }
    }

    attached() {
        window.setTimeout(() => {
            try {
                this._element.closest('ux-dialog-container').style.zIndex = 999999;
            } catch (err) {
                console.log(err);
            }
        }, 0);
    }

    confirm() {
        this.dialogController.ok({ value: this.value });
    }
}
