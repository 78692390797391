import moment from 'moment';

/* sign-up */
class Registration {
	firstName = '';
	lastName = '';
	dateOfBirth = '';
	email = '';
	password = '';
	streetAddress = '';
	streetAddress2 = '';
	city = '';
	state = '';
	zip = '';
	cellPhone = '';
	isLicensedLife = false;
	isLicensedHealth = false;
	parentMemberId = '';
    nationalProducerNumber = '';

	constructor() {
	}
}

/* update profile */
class MemberUpdate {
	id = '';
	firstName = '';
	lastName = '';
	fullName = '';
	teamName = '';
	email = '';
	dateOfBirth = '';
	streetAddress = '';
	streetAddress2 = '';
	city = '';
	state = '';
	zip = '';
	businessPhone = '';
	cellPhone = '';
	isLicensedLife = false;
	isLicensedHealth = false;
    nationalProducerNumber = '';
}

/* profile */
class MemberProfile {
	id = '';
	firstName = '';
	lastName = '';
	fullName = '';
	email = '';
	password = '';
	dateOfBirth = '';
	streetAddress = '';
	streetAddress2 = '';
	city = '';
	state = '';
	zip = '';
	businessPhone = '';
	cellPhone = '';
	isLicensedLife = false;
	isLicensedHealth = false;
	parentMemberId = '';
	lifeLevel = '';
	imageUrl = '';
    isActive = false;
    inactiveReason = '';
}

/* parent */
class MiniMemberProfile {
	id = '';
	fullName = '';
	email = '';
	phone = '';
	imageUrl = '';
}

/* reset password */
class ResetPassword {
	email = '';
}

class ResetPasswordConfirmation {
	email = '';
	password = '';
	token = '';
}

/* FileKey */
class FileKey {
	id = '';
	requestedByUserId = '';
}

var m = {
	Registration: Registration,
	MemberUpdate: MemberUpdate,
	MemberProfile: MemberProfile,
	MiniMemberProfile: MiniMemberProfile,
	ResetPassword: ResetPassword,
	ResetPasswordConfirmation: ResetPasswordConfirmation,
	FileKey: FileKey,
};

export {
	m
}
