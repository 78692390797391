import { LpfnUtil } from 'common/utils';

export class Drawer {
    element;

    defaultOptions = {
        overlay: true,
        direction: 'end',
        baseClass: 'drawer',
        overlayClass: 'drawer-overlay'
    };

    options = {};

    constructor(element, options) {
        this.element = element;
        this.options = options;
        this._init();
    }

    _init() {
        this.options = LpfnUtil.deepExtend({}, this.defaultOptions, this.options);
        this.uid = LpfnUtil.getUniqueId('drawer');
        this.overlayElement = null;
        this.name = this.element.getAttribute('data-kt-drawer-name');
        this.shown = false;
        this.lastWidth;
        this.toggleElement = null;

        this.element.setAttribute('data-kt-drawer', 'true');

        this.update();
    }

    toggle() {
        if (this.shown === true) {
            this.hide();
        } else {
            this.show();
        }
    }

    hide() {
        this.shown = false;

        this._deleteOverlay();

        document.body.removeAttribute('data-kt-drawer-' + this.name, 'on');
        document.body.removeAttribute('data-kt-drawer');

        LpfnUtil.removeClass(this.element, this.options.baseClass);
        LpfnUtil.removeClass(this.element, this.options.baseClass + '-on');

        if (this.toggleElement !== null) {
            LpfnUtil.removeClass(this.toggleElement, 'active');
        }
    }

    show() {
        this.shown = true;

        this._createOverlay();
        document.body.setAttribute('data-kt-drawer-' + this.name, 'on');
        document.body.setAttribute('data-kt-drawer', 'on');

        LpfnUtil.addClass(this.element, this.options.baseClass);
        LpfnUtil.addClass(this.element, this.options.baseClass + '-on');

        if (this.toggleElement !== null) {
            LpfnUtil.addClass(this.toggleElement, 'active');
        }
    }

    update() {
        var width = this._getWidth();
        var direction = this._getOption('direction');

        var top = this._getOption('top');
        var bottom = this._getOption('bottom');
        var start = this._getOption('start');
        var end = this._getOption('end');

        // Reset state
        if (LpfnUtil.hasClass(this.element, this.options.baseClass + '-on') === true && String(document.body.getAttribute('data-kt-drawer-' + this.name + '-')) === 'on') {
            this.shown = true;
        } else {
            this.shown = false;
        }       

        // Activate/deactivate
        if (this._getOption('activate') === true ) {
            LpfnUtil.addClass(this.element, this.options.baseClass);
            LpfnUtil.addClass(this.element, this.options.baseClass + '-' + direction);
            
            LpfnUtil.css(this.element, 'width', width, true);
            this.lastWidth = width;

            if (top) {
                LpfnUtil.css(this.element, 'top', top);
            }

            if (bottom) {
                LpfnUtil.css(this.element, 'bottom', bottom);
            }

            if (start) {
                LpfnUtil.css(this.element, 'left', start);
            }

            if (end) {
                LpfnUtil.css(this.element, 'right', end);
            }
        } else {
            LpfnUtil.removeClass(this.element, this.options.baseClass);
            LpfnUtil.removeClass(this.element, this.options.baseClass + '-' + direction);

            LpfnUtil.css(this.element, 'width', '');

            if (top) {
                LpfnUtil.css(this.element, 'top', '');
            }

            if (bottom) {
                LpfnUtil.css(this.element, 'bottom', '');
            }

            if (start) {
                if (LpfnUtil.isRTL()) {
                    LpfnUtil.css(this.element, 'right', '');
                } else {
                    LpfnUtil.css(this.element, 'left', '');
                }
            }

            if (end) {
                if (LpfnUtil.isRTL()) {
                    LpfnUtil.css(this.element, 'left', '');
                } else {
                    LpfnUtil.css(this.element, 'right', '');
                }
            }

            this.hide();
        }
    }

    _createOverlay() {
        if (this._getOption('overlay') === true) {
            this.overlayElement = document.createElement('DIV');

            LpfnUtil.css(this.overlayElement, 'z-index', LpfnUtil.css(this.element, 'z-index') - 1); // update

            document.body.append(this.overlayElement);

            LpfnUtil.addClass(this.overlayElement, this._getOption('overlay-class'));

            LpfnUtil.addEvent(this.overlayElement, 'click', (e) => {
                e.preventDefault();
                this.hide();
            });
        }
    }

    _deleteOverlay() {
        if (this.overlayElement !== null ) {
            LpfnUtil.remove(this.overlayElement);
        }
    }

    _getOption(name) {
        if (this.element.hasAttribute('data-kt-drawer-' + name) === true) {
            var attr = this.element.getAttribute('data-kt-drawer-' + name);
            var value = LpfnUtil.getResponsiveValue(attr);

            if (value !== null && String(value) === 'true') {
                value = true;
            } else if (value !== null && String(value) === 'false') {
                value = false;
            }

            return value;
        } else {
            var optionName = LpfnUtil.snakeToCamel(name);

            if (this.options[optionName]) {
                return LpfnUtil.getResponsiveValue(this.options[optionName]);
            } else {
                return null;
            }
        }
    }

    _getWidth() {
        var width = this._getOption('width');

        if (width === 'auto') {
            width = LpfnUtil.css(this.element, 'width');
        }

        return width;
    }
}

