import { EventAggregator } from 'aurelia-event-aggregator';

export class Content {
    static inject = [EventAggregator];
    _ea;
    _fileCallback;

    _initialized = false;
    _structuredPages = {};

    constructor(ea) {
        this._ea = ea;
    }

    initialize() {
        this._fileManagerEl = document.getElementById('lpfnFileManagerEl');
        this._fileManagerEl.addEventListener('fileSelected', (e) => this.fileSelected(e));
        this._initialized = true;
    }

    async fileManager(fileCallback, fileType = 'all') {
        if (!this._initialized) this.initialize();
        this._fileCallback = fileCallback;
        this._ea.publish('lpfn-open-file-manager', { fileType });
    }

    fileSelected(e) {
        if (!e || !e.detail) return;
        e.detail.path = `api/file/${e.detail.id}`;
        this._fileCallback(e.detail);
    }
}
