import { Versions } from 'services/versions';
import { Security } from 'common/security';

export class Footer {
    static inject = [Versions, Security];
    currentYear = new Date().getFullYear();

    constructor(versions, security) {
        this.version = versions.version;
        this.security = security;
    }
}