class Utils {
    resizeHandlers = [];

    constructor() {
        this._windowResizeHandler();
    }

    /**
     * Handle window resize event with some
     * delay to attach event handlers upon resize complete
     */
    _windowResizeHandler() {
        var _runResizeHandlers = () => {
            // reinitialize other subscribed elements
            for (var i = 0; i < this.resizeHandlers.length; i++) {
                var each = this.resizeHandlers[i];
                if (each && each.call) each.call();
            }
        };

        var timer;

        window.addEventListener('resize', () => {
            this.throttle(timer, function() {
                _runResizeHandlers();
            }, 200);
        });
    };
    
    /**
     * Adds window resize event handler.
     * @param {function} callback function.
     */
    addResizeHandler(cb) {
        this.resizeHandlers.push(cb);
    }

    /**
     * Removes window resize event handler.
     * @param {function} callback function.
     */
    removeResizeHandler(cb) {
        for (var i = 0; i < this.resizeHandlers.length; i++) {
            if (cb === this.resizeHandlers[i]) {
                delete this.resizeHandlers[i];
            }
        }
    }

    /**
     * Trigger window resize handlers.
     */
    runResizeHandlers() {
        _runResizeHandlers();
    }

    resize() {
        window.dispatchEvent(new Event('resize'));
    }

    /**
     * Get GET parameter value from URL.
     * @param {string} paramName Parameter name.
     * @returns {string}
     */
    getURLParam(paramName) {
        var searchString = window.location.search.substring(1),
            i, val, params = searchString.split("&");

        for (i = 0; i < params.length; i++) {
            val = params[i].split("=");
            if (val[0] == paramName) {
                return unescape(val[1]);
            }
        }

        return null;
    }

    /**
     * Gets browser window viewport size. Ref:
     * http://andylangton.co.uk/articles/javascript/get-viewport-size-javascript/
     * @returns {object}
     */
    getViewPort() {
        var e = window,
            a = 'inner';
        if (!('innerWidth' in window)) {
            a = 'client';
            e = document.documentElement || document.body;
        }

        return {
            width: e[a + 'Width'],
            height: e[a + 'Height']
        };
    }

    /**
     * Checks whether given device mode is currently activated.
     * @param {string} mode Responsive mode name(e.g: desktop,
     *     desktop-and-tablet, tablet, tablet-and-mobile, mobile)
     * @returns {boolean}
     */
    isBreakpointUp(mode) {
        var width = this.getViewPort().width;
        var breakpoint = this.getBreakpoint(mode);

        return (width >= breakpoint);
    }

    isBreakpointDown(mode) {
        var width = this.getViewPort().width;
        var breakpoint = this.getBreakpoint(mode);

        return (width < breakpoint);
    }

    getViewportWidth() {
        return this.getViewPort().width;
    }

    /**
     * Generates unique ID for give prefix.
     * @param {string} prefix Prefix for generated ID
     * @returns {boolean}
     */
    getUniqueId(prefix) {
        return prefix + Math.floor(Math.random() * (new Date()).getTime());
    }

    /**
     * Gets window width for give breakpoint mode.
     * @param {string} mode Responsive mode name(e.g: xl, lg, md, sm)
     * @returns {number}
     */
    getBreakpoint(breakpoint) {
        var value = this.getCssVariableValue('--kt-' + breakpoint);

        if ( value ) {
            value = parseInt(value.trim());
        } 

        return value;
    }

    /**
     * Simulates delay
     */
    sleep(milliseconds) {
        var start = new Date().getTime();
        for (var i = 0; i < 1e7; i++) {
            if ((new Date().getTime() - start) > milliseconds) {
                break;
            }
        }
    }

    /**
     * Gets randomly generated integer value within given min and max range
     * @param {number} min Range start value
     * @param {number} max Range end value
     * @returns {number}
     */
    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    triggerCustomEvent(el, eventName, data) {
        const event = new CustomEvent(eventName, { detail: data });
        el.dispatchEvent(event);
    }

    outerWidth(el, margin) {
        var width;

        if (margin === true) {
            width = parseFloat(el.offsetWidth);
            width += parseFloat(this.css(el, 'margin-left')) + parseFloat(this.css(el, 'margin-right'));

            return parseFloat(width);
        } else {
            width = parseFloat(el.offsetWidth);

            return width;
        }
    }

    offset(el) {
        var rect, win;

        if ( !el ) {
            return;
        }

        // Return zeros for disconnected and hidden (display: none) elements (gh-2310)
        // Support: IE <=11 only
        // Running getBoundingClientRect on a
        // disconnected node in IE throws an error

        if ( !el.getClientRects().length ) {
            return { top: 0, left: 0 };
        }

        // Get document-relative position by adding viewport scroll to viewport-relative gBCR
        rect = el.getBoundingClientRect();
        win = el.ownerDocument.defaultView;

        return {
            top: rect.top + win.pageYOffset,
            left: rect.left + win.pageXOffset,
            right: window.innerWidth - (el.offsetLeft + el.offsetWidth)
        };
    }

    height(el) {
        return this.css(el, 'height');
    }

    outerHeight(el, withMargin) {
        var height = el.offsetHeight;
        var style;

        if (typeof withMargin !== 'undefined' && withMargin === true) {
            style = getComputedStyle(el);
            height += parseInt(style.marginTop) + parseInt(style.marginBottom);

            return height;
        } else {
            return height;
        }
    }

    getRelativeTopPosition(el, container) {
        return el.offsetTop - container.offsetTop;
    }

    animate(from, to, duration, update, easing, done) {
        /**
         * TinyAnimate.easings
         *  Adapted from jQuery Easing
         */
        var easings = {};
        var easing;

        easings.linear = function(t, b, c, d) {
            return c * t / d + b;
        };

        easing = easings.linear;

        // Early bail out if called incorrectly
        if (typeof from !== 'number' ||
            typeof to !== 'number' ||
            typeof duration !== 'number' ||
            typeof update !== 'function') {
            return;
        }

        // Create mock done() function if necessary
        if (typeof done !== 'function') {
            done = function() {};
        }

        // Pick implementation (requestAnimationFrame | setTimeout)
        var rAF = window.requestAnimationFrame || function(callback) {
            window.setTimeout(callback, 1000 / 50);
        };

        // Animation loop
        var canceled = false;
        var change = to - from;

        function loop(timestamp) {
            var time = (timestamp || +new Date()) - start;

            if (time >= 0) {
                update(easing(time, from, change, duration));
            }
            if (time >= 0 && time >= duration) {
                update(to);
                done();
            } else {
                rAF(loop);
            }
        }

        update(from);

        // Start animation loop
        var start = window.performance && window.performance.now ? window.performance.now() : +new Date();

        rAF(loop);
    }

    getScroll(element, method) {
        // The passed in `method` value should be 'Top' or 'Left'
        method = 'scroll' + method;
        return (element == window || element == document) ? (
            self[(method == 'scrollTop') ? 'pageYOffset' : 'pageXOffset'] ||
            (browserSupportsBoxModel && document.documentElement[method]) ||
            document.body[method]
        ) : element[method];
    }

    css(el, styleProp, value, important) {
        if (!el) {
            return;
        }

        if (value !== undefined) {
            if ( important === true ) {
                el.style.setProperty(styleProp, value, 'important');
            } else {
                el.style[styleProp] = value;
            }
        } else {
            var defaultView = (el.ownerDocument || document).defaultView;

            // W3C standard way:
            if (defaultView && defaultView.getComputedStyle) {
                // sanitize property name to css notation
                // (hyphen separated words eg. font-Size)
                styleProp = styleProp.replace(/([A-Z])/g, "-$1").toLowerCase();

                return defaultView.getComputedStyle(el, null).getPropertyValue(styleProp);
            } else if (el.currentStyle) { // IE
                // sanitize property name to camelCase
                styleProp = styleProp.replace(/\-(\w)/g, function(str, letter) {
                    return letter.toUpperCase();
                });

                value = el.currentStyle[styleProp];

                // convert other units to pixels on IE
                if (/^\d+(em|pt|%|ex)?$/i.test(value)) {
                    return (function(value) {
                        var oldLeft = el.style.left, oldRsLeft = el.runtimeStyle.left;

                        el.runtimeStyle.left = el.currentStyle.left;
                        el.style.left = value || 0;
                        value = el.style.pixelLeft + "px";
                        el.style.left = oldLeft;
                        el.runtimeStyle.left = oldRsLeft;

                        return value;
                    })(value);
                }

                return value;
            }
        }
    }

    slide(el, dir, speed, callback) {
        if (!el || (dir == 'up' && this.visible(el) === false) || (dir == 'down' && this.visible(el) === true)) {
            return;
        }

        speed = (speed ? speed : 600);
        var calcHeight = this.actualHeight(el);
        var calcPaddingTop = false;
        var calcPaddingBottom = false;

        if (dir == 'up') { // up
            el.style.cssText = 'display: block; overflow: hidden;';

            if (calcPaddingTop) {
                this.animate(0, calcPaddingTop, speed, function(value) {
                    el.style.paddingTop = (calcPaddingTop - value) + 'px';
                }, 'linear');
            }

            if (calcPaddingBottom) {
                this.animate(0, calcPaddingBottom, speed, function(value) {
                    el.style.paddingBottom = (calcPaddingBottom - value) + 'px';
                }, 'linear');
            }

            this.animate(0, calcHeight, speed, function(value) {
                el.style.height = (calcHeight - value) + 'px';
            }, 'linear', function() {
                el.style.height = '';
                el.style.display = 'none';

                if (typeof callback === 'function') {
                    callback();
                }
            });
        } else if (dir == 'down') { // down
            el.style.cssText = 'display: block; overflow: hidden;';

            if (calcPaddingTop) {
                this.animate(0, calcPaddingTop, speed, function(value) {//
                    el.style.paddingTop = value + 'px';
                }, 'linear', function() {
                    el.style.paddingTop = '';
                });
            }

            if (calcPaddingBottom) {
                this.animate(0, calcPaddingBottom, speed, function(value) {
                    el.style.paddingBottom = value + 'px';
                }, 'linear', function() {
                    el.style.paddingBottom = '';
                });
            }

            this.animate(0, calcHeight, speed, function(value) {
                el.style.height = value + 'px';
            }, 'linear', function() {
                el.style.height = '';
                el.style.display = '';
                el.style.overflow = '';

                if (typeof callback === 'function') {
                    callback();
                }
            });
        }
    }

    slideUp(el, speed, callback) {
        this.slide(el, 'up', speed, callback);
    }

    slideDown(el, speed, callback) {
        this.slide(el, 'down', speed, callback);
    }

    show(el, display) {
        if (typeof el !== 'undefined') {
            el.style.display = (display ? display : 'block');
        }
    }

    hide(el) {
        if (typeof el !== 'undefined') {
            el.style.display = 'none';
        }
    }

    animateClass(el, animationName, callback) {
        var animation;
        var animations = {
            animation: 'animationend',
            OAnimation: 'oAnimationEnd',
            MozAnimation: 'mozAnimationEnd',
            WebkitAnimation: 'webkitAnimationEnd',
            msAnimation: 'msAnimationEnd',
        };

        for (var t in animations) {
            if (el.style[t] !== undefined) {
                animation = animations[t];
            }
        }
        
        this.addClass(el, animationName);

        this.one(el, animation, function() {
            this.removeClass(el, animationName);
        });

        if (callback) {
            this.one(el, animation, callback);
        }
    }

    transitionEnd(el, callback) {
        var transition;
        var transitions = {
            transition: 'transitionend',
            OTransition: 'oTransitionEnd',
            MozTransition: 'mozTransitionEnd',
            WebkitTransition: 'webkitTransitionEnd',
            msTransition: 'msTransitionEnd'
        };

        for (var t in transitions) {
            if (el.style[t] !== undefined) {
                transition = transitions[t];
            }
        }

        this.one(el, transition, callback);
    }

    animationEnd(el, callback) {
        var animation;
        var animations = {
            animation: 'animationend',
            OAnimation: 'oAnimationEnd',
            MozAnimation: 'mozAnimationEnd',
            WebkitAnimation: 'webkitAnimationEnd',
            msAnimation: 'msAnimationEnd'
        };

        for (var t in animations) {
            if (el.style[t] !== undefined) {
                animation = animations[t];
            }
        }

        this.one(el, animation, callback);
    }

    animateDelay(el, value) {
        var vendors = ['webkit-', 'moz-', 'ms-', 'o-', ''];
        for (var i = 0; i < vendors.length; i++) {
            this.css(el, vendors[i] + 'animation-delay', value);
        }
    }

    animateDuration(el, value) {
        var vendors = ['webkit-', 'moz-', 'ms-', 'o-', ''];
        for (var i = 0; i < vendors.length; i++) {
            this.css(el, vendors[i] + 'animation-duration', value);
        }
    }

    scrollTo(target, offset, duration) {
        var duration = duration ? duration : 500;
        var targetPos = target ? this.offset(target).top : 0;
        var scrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        var from, to;

        if (offset) {
            targetPos = targetPos - offset;
        }

        from = scrollPos;
        to = targetPos;

        this.animate(from, to, duration, function(value) {
            document.documentElement.scrollTop = value;
            document.body.parentNode.scrollTop = value;
            document.body.scrollTop = value;
        }); //, easing, done
    }

    scrollTop(offset, duration) {
        this.scrollTo(null, offset, duration);
    }



    scrollToElm(container, elm, durationSeconds) {
        const pos = this.getRelativePos(elm, container);
        this.smoothScrollTo(container, pos.top , durationSeconds/1000);
    }
      
    getRelativePos(elm, containerNode) {
        let pPos = containerNode ? containerNode.getBoundingClientRect() : elm.parentNode.getBoundingClientRect(); // parent pos
        let cPos = elm.getBoundingClientRect(); // target pos
        let pos = {};
      
        pos.top    = cPos.top    - pPos.top + elm.parentNode.scrollTop,
        pos.right  = cPos.right  - pPos.right,
        pos.bottom = cPos.bottom - pPos.bottom,
        pos.left   = cPos.left   - pPos.left;
      
        return pos;
    }
          
    smoothScrollTo(element, to, duration, onDone) {
        let start = element.scrollTop,
            change = to - start,
            startTime = performance.now(),
            val, now, elapsed, t;
      
        const animateScroll = () => {
            now = performance.now();
            elapsed = (now - startTime)/1000;
            t = (elapsed/duration);
      
            element.scrollTop = start + change * this.easeInOutQuad(t);
      
            if( t < 1 )
                window.requestAnimationFrame(animateScroll);
            else
                onDone && onDone();
        };
      
        animateScroll();
    }
      
    easeInOutQuad(t) {
        return t<.5 ? 2*t*t : -1+(4-2*t)*t;
    }

    isArray(obj) {
        return obj && Array.isArray(obj);
    }

    uniqueArray(array) {
        return [...new Set(array)];
    }

    getDocumentHeight() {
        var body = document.body;
        var html = document.documentElement;

        return Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
    }

    getScrollTop() {
        return  (document.scrollingElement || document.documentElement).scrollTop;
    }

    colorLighten(color, amount) {
        const addLight = function(color, amount){
            let cc = parseInt(color,16) + amount;
            let c = (cc > 255) ? 255 : (cc);
            c = (c.toString(16).length > 1 ) ? c.toString(16) : `0${c.toString(16)}`;
            return c;
        }

        color = (color.indexOf("#")>=0) ? color.substring(1,color.length) : color;
        amount = parseInt((255*amount)/100);
        
        return color = `#${addLight(color.substring(0,2), amount)}${addLight(color.substring(2,4), amount)}${addLight(color.substring(4,6), amount)}`;
    }

    colorDarken(color, amount) {
        const subtractLight = function(color, amount){
            let cc = parseInt(color,16) - amount;
            let c = (cc < 0) ? 0 : (cc);
            c = (c.toString(16).length > 1 ) ? c.toString(16) : `0${c.toString(16)}`;

            return c;
        }
            
        color = (color.indexOf("#")>=0) ? color.substring(1,color.length) : color;
        amount = parseInt((255*amount)/100);

        return color = `#${subtractLight(color.substring(0,2), amount)}${subtractLight(color.substring(2,4), amount)}${subtractLight(color.substring(4,6), amount)}`;
    }

    componentToHex(c) {
        let hex = c.toString(16);
        return hex.length == 1 ? '0' + hex : hex;
    }
      
    rgbToHex(r, g, b) {
        return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    hexToRgb(hex) {
        try {
            let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
            } : null;
        } catch (err) {
            return null;
        }
    }

    hexToRgbString(hex, opacity, defaultColor = 'transparent') {
        let rgb = this.hexToRgb(hex);
        if (!rgb) {
            if (defaultColor !== 'transparent') {
                rgb = this.hexToRgb(defaultColor);
            }
            if (!rgb) return defaultColor;
        }
        if (opacity) return `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity})`;
        return `rgb(${rgb.r},${rgb.g},${rgb.b})`;
    }

    // Throttle function: Input as function which needs to be throttled and delay is the time interval in milliseconds
    throttle(timer, func, delay) {
        // If setTimeout is already scheduled, no need to do anything
        if (timer) {
            return;
        }

        // Schedule a setTimeout after delay seconds
        timer  =  setTimeout(function () {
            func();

            // Once setTimeout function execution is finished, timerId = undefined so that in <br>
            // the next scroll event function execution can be scheduled by the setTimeout
            timer  =  undefined;
        }, delay);
    }

    // Debounce function: Input as function which needs to be debounced and delay is the debounced time in milliseconds
    debounce(timer, func, delay) {
        // Cancels the setTimeout method execution
        clearTimeout(timer)

        // Executes the func after delay time.
        timer  =  setTimeout(func, delay);
    }

    getResponsiveValue(value, defaultValue) {
        var width = this.getViewPort().width;
        var result;

        value = this.parseJson(value);

        if (typeof value === 'object') {
            var resultKey;
            var resultBreakpoint = -1;
            var breakpoint;

            for (var key in value) {
                if (key === 'default') {
                    breakpoint = 0;
                } else {
                    breakpoint = this.getBreakpoint(key) ? this.getBreakpoint(key) : parseInt(key);
                }

                if (breakpoint <= width && breakpoint > resultBreakpoint) {
                    resultKey = key;
                    resultBreakpoint = breakpoint;
                }
            }

            if (resultKey) {
                result = value[resultKey];
            } else {
                result = value;
            }
        } else {
            result = value;
        }

        return result;
    }

    isInViewport(element) {
        const rect = element.getBoundingClientRect();

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    inViewportHandler(element, callback, callbackAdditionalData) {
        try {
            const observer = new IntersectionObserver((entries, opts) => {
                entries.forEach(entry => {
                    callback(entry.isIntersecting, callbackAdditionalData);
                });              
            }, { root: null, threshold: .5 });
            observer.observe(element);
            return {
                dispose: () => {
                    observer.unobserve(element);
                },
            }
        } catch (err) {
            console.log('no IntersectionObserver support');
        }
    }

    inViewportOnIntersection = (entries, opts) => {
        entries.forEach(entry => entry.target.classList.toggle('visible', entry.isIntersecting));
    }

    isHexColor(code) {
        return /^#[0-9A-F]{6}$/i.test(code);
    }

    /**
     * Checks whether the element has given classes
     * @param {object} el jQuery element object
     * @param {string} Classes string
     * @returns {boolean}
     */
    hasClasses(el, classes) {
        if (!el) return;

        var classesArr = classes.split(" ");

        for (var i = 0; i < classesArr.length; i++) {
            if (this.hasClass(el, classesArr[i].trim()) == false) return false;
        }

        return true;
    }

    hasClass(el, className) {
        if (!el) return;

        return el.classList ? el.classList.contains(className) : new RegExp('\\b' + className + '\\b').test(el.className);
    }

    addClass(el, className) {
        if (!el || typeof className === 'undefined') return;

        var classNames = className.split(' ');

        if (el.classList) {
            for (var i = 0; i < classNames.length; i++) {
                if (classNames[i] && classNames[i].length > 0) {
                    el.classList.add(classNames[i].trim());
                }
            }
        } else if (!this.hasClass(el, className)) {
            for (var x = 0; x < classNames.length; x++) {
                el.className += ' ' + classNames[x].trim();
            }
        }
    }

    removeClass(el, className) {
        if (!el || typeof className === 'undefined') return;

        var classNames = className.split(' ');

        if (el.classList) {
            for (var i = 0; i < classNames.length; i++) {
                el.classList.remove(classNames[i].trim());
            }
        } else if (this.hasClass(el, className)) {
            for (var x = 0; x < classNames.length; x++) {
                el.className = el.className.replace(new RegExp('\\b' + classNames[x].trim() + '\\b', 'g'), '');
            }
        }
    }

    visible(el) {
        return !(el.offsetWidth === 0 && el.offsetHeight === 0);
    }

    isVisibleInContainer(el, container) {
        const eleTop = el.offsetTop;
        const eleBottom = eleTop + el.clientHeight;
    
        const containerTop = container.scrollTop;
        const containerBottom = containerTop + container.clientHeight;
    
        // The element is fully visible in the container
        return (
            (eleTop >= containerTop && eleBottom <= containerBottom) ||
            // Some part of the element is visible in the container
            (eleTop < containerTop && containerTop < eleBottom) ||
            (eleTop < containerBottom && containerBottom < eleBottom)
        );
    }

    actualCss(el, prop, cache) {
        var css = '';

        if (el instanceof HTMLElement === false) {
            return;
        }

        if (!el.getAttribute('kt-hidden-' + prop) || cache === false) {
            var value;

            // the element is hidden so:
            // making the el block so we can meassure its height but still be hidden
            css = el.style.cssText;
            el.style.cssText = 'position: absolute; visibility: hidden; display: block;';

            if (prop == 'width') {
                value = el.offsetWidth;
            } else if (prop == 'height') {
                value = el.offsetHeight;
            }

            el.style.cssText = css;

            // store it in cache
            el.setAttribute('kt-hidden-' + prop, value);

            return parseFloat(value);
        } else {
            // store it in cache
            return parseFloat(el.getAttribute('kt-hidden-' + prop));
        }
    }

    actualHeight(el, cache) {
        return this.actualCss(el, 'height', cache);
    }

    actualWidth(el, cache) {
        return this.actualCss(el, 'width', cache);
    }

    /**
     * Throttle function: Input as function which needs to be throttled and delay is the time interval in milliseconds
     * @param {*} timer 
     * @param {*} func 
     * @param {*} delay 
     * @returns 
     */
    throttle(timer, func, delay) {
        if (timer) return;

        timer = setTimeout(() => {
            func();
            timer = undefined;
        }, delay);
    }

    /**
     * Debounce function: Input as function which needs to be debounced and delay is the debounced time in milliseconds
     * @param {*} timer 
     * @param {*} func 
     * @param {*} delay 
     */
    debounce(timer, func, delay) {
        clearTimeout(timer)
        timer = setTimeout(func, delay);
    }

    /**
     * Deep extend:  $.extend(true, {}, objA, objB);
     * @param {Object} out 
     * @returns {Object}
     */
    deepExtend(out) {
        out = out || {};
        for (var i = 1; i < arguments.length; i++) {
            var obj = arguments[i];
            if (!obj) continue;

            for (var key in obj) {
                if (!obj.hasOwnProperty(key)) {
                    continue;
                }

                // based on https://javascriptweblog.wordpress.com/2011/08/08/fixing-the-javascript-typeof-operator/
                if ( Object.prototype.toString.call(obj[key]) === '[object Object]' ) {
                    out[key] = this.deepExtend(out[key], obj[key]);
                    continue;
                }
                out[key] = obj[key];
            }
        }
        return out;
    }

    /**
     * extend:  $.extend({}, objA, objB);
     */
    extend(out) {
        out = out || {};
        for (var i = 1; i < arguments.length; i++) {
            if (!arguments[i])
                continue;

            for (var key in arguments[i]) {
                if (arguments[i].hasOwnProperty(key))
                    out[key] = arguments[i][key];
            }
        }
        return out;
    }

    snakeToCamel(s){
        return s.replace(/(\-\w)/g, function(m){return m[1].toUpperCase();});
    }

    parseJson(json) {
        if (typeof json === 'string') {
            json = json.replace(/'/g, "\"");

            var jsonStr = json.replace(/(\w+:)|(\w+ :)/g, (matched) => {
                return '"' + matched.substring(0, matched.length - 1) + '":';
            });

            try {
                json = JSON.parse(jsonStr);
            } catch(e) { }
        }

        return json;
    }

    addEvent(el, type, handler) {
        if (typeof el !== 'undefined' && el !== null) {
            el.addEventListener(type, handler);
        }
    }

    removeEvent(el, type, handler) {
        if (el !== null) {
            el.removeEventListener(type, handler);
        }
    }

    remove(el) {
        if (el && el.parentNode) {
            el.parentNode.removeChild(el);
        }
    }

    find(parent, query) {
        if ( parent !== null) {
            return parent.querySelector(query);
        } else {
            return null;
        }
    }

    findAll(parent, query) {
        if ( parent !== null ) {
            return parent.querySelectorAll(query);
        } else {
            return null;
        }
    }

    insertAfter(el, referenceNode) {
        return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
    }

    parents(elem, selector) {
        // Set up a parent array
        var parents = [];

        // Push each parent element to the array
        for ( ; elem && elem !== document; elem = elem.parentNode ) {
            if (selector) {
                if (elem.matches(selector)) {
                    parents.push(elem);
                }
                continue;
            }
            parents.push(elem);
        }

        // Return our parent array
        return parents;
    }

    children(el, selector, log) {
        if (!el || !el.childNodes) {
            return null;
        }

        var result = [],
            i = 0,
            l = el.childNodes.length;

        for (var i; i < l; ++i) {
            if (el.childNodes[i].nodeType == 1 && this.matches(el.childNodes[i], selector, log)) {
                result.push(el.childNodes[i]);
            }
        }

        return result;
    }

    child(el, selector, log) {
        var children = this.children(el, selector, log);

        return children ? children[0] : null;
    }

    matches(el, selector, log) {
        var p = Element.prototype;
        var f = p.matches || p.webkitMatchesSelector || p.mozMatchesSelector || p.msMatchesSelector || function(s) {
            return [].indexOf.call(document.querySelectorAll(s), this) !== -1;
        };

        if (el && el.tagName) {
            return f.call(el, selector);
        } else {
            return false;
        }
    }

    getCssVariableValue(variableName) {
        var hex = getComputedStyle(document.documentElement).getPropertyValue(variableName);
        if ( hex && hex.length > 0 ) {
            hex = hex.trim();
        }

        return hex;
    }

    /**
     * Gets highest z-index of the given element parents
     * @param {object} el jQuery element object
     * @returns {number}
     */
    getHighestZindex(el) {
        var position, value;

        while (el && el !== document) {
            // Ignore z-index if position is set to a value where z-index is ignored by the browser
            // This makes behavior of this function consistent across browsers
            // WebKit always returns auto if the element is positioned
            position = this.css(el, 'position');

            if (position === "absolute" || position === "relative" || position === "fixed") {
                // IE returns 0 when zIndex is not specified
                // other browsers return a string
                // we ignore the case of nested elements with an explicit value of 0
                // <div style="z-index: -10;"><div style="z-index: 0;"></div></div>
                value = parseInt(this.css(el, 'z-index'));

                if (!isNaN(value) && value !== 0) {
                    return value;
                }
            }

            el = el.parentNode;
        }

        return 1;
    }
}

export const LpfnUtil = new Utils();
