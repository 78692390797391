import { I18N } from 'aurelia-i18n';
import { EventAggregator } from 'aurelia-event-aggregator';
import { BindingSignaler } from 'aurelia-templating-resources';
import { signalBindings } from 'aurelia-binding';
import { Api } from 'common/server';
import moment from 'moment';
import 'moment/locale/es'; // this will include this file in the bundle so it can be loaded

export class I18n {
    static inject = [I18N, EventAggregator, BindingSignaler, Api];
    _ai18n;
    _ea;
    _bindingSignaler;
    _api;

    currentLocale = '';
    language = null;
    nf = null;
    supportedLanguages = [];
    defaultLocale = 'en';

    constructor(i18n, ea, bindingSignaler, api) {
        this._ai18n = i18n;
        this._ea = ea;
        this._bindingSignaler = bindingSignaler;
        this._api = api;

        this.currentLocale = this._ai18n.getLocale() || 'en-US';
        if (this.currentLocale !== 'en-US') {
            this._ai18n.setLocale(this.currentLocale).then(() => {
                this._loadMoment();
            });
        } else {
            this._loadMoment();
        }
    }

    initialize() {
        return this._loadSupportedLanguages();
    }

    languageName(locale) {
        for (let i = 0; i < this.supportedLanguages.length; i++) {
            if (this.supportedLanguages[i].locale !== locale) continue;
            return this.supportedLanguages[i].name;
        }
        return locale;
    }

    _loadMoment() {
        moment.locale(this.currentLocale);
        if (moment.locale() !== this.currentLocale) {
            // default to en, importing the locale files set the moment locale to the last one imported
            moment.locale(this.defaultLocale);
        }
    }

    async _loadSupportedLanguages() {
        try {
            const data = await this._api.get('content/supported-languages');
            for (var i = 0; i < data.length; i++) {
                let lang = { locale: data[i], name: this.tr(`i18n-languages-${data[i]}`) };
                this.supportedLanguages.push(lang);
                if (lang.locale === this.currentLocale) this.language = lang;
                else if (this.currentLocale.indexOf(lang.locale) === 0) this.language = lang;
            }
            if (!this.language && this.supportedLanguages.length > 0) this.language = this.supportedLanguages[0];
        } catch (err) {
            console.log(err);
        }
    }

    clear() {
        localStore.clear('i18nextLng'); //from language detector
    }

    async setLocale(locale) {
        try {
            if (locale === this._ai18n.getLocale()) return;
            const fromLocale = this.currentLocale;
            this.currentLocale = locale;
            await this._loadMoment();
            await this._ai18n.setLocale(this.currentLocale);
            await this.reloadTranslations(fromLocale);
        } catch (err) {
            console.log(err);
            this.currentLocale = this.defaultLocale;
            await this._loadMoment();
            await this._ai18n.setLocale(this.currentLocale);
        }
    }

    async loadNamespace(ns) {
        return new Promise((resolve, reject) => {
            this._ai18n.i18next.loadNamespaces(ns, () => {
                resolve();
            });
        });
    }

    async reloadTranslations(fromLocale) {
        try {
            await this._ai18n.i18next.reloadResources();
            if (fromLocale) this._ea.publish('i18n:locale:changed', { oldValue: fromLocale, newValue: this.currentLocale });
            this._bindingSignaler.signal('aurelia-translation-signal');
            signalBindings('locale-changed');
        } catch (err) {
            console.log(err);
        }
    }

    tr(key, data) {
        if (typeof data === 'object') return this._ai18n.tr(key, data);
        if (data != null && data != undefined) return this._ai18n.tr(key, {count: data});
        return this._ai18n.tr(key);
    }

    formatDate(value, formatter) {
        if (!value) return null;
        if (!formatter) formatter = 'lll';
        return moment(value).format(formatter)
    }

    formatNumber(value, decimals, prefix = '', addPlusIfPositive = false) {
        var n = Number(value);
        if (n === NaN) return value;
        if (decimals === 0) n = n.toFixed(0);
        else if (decimals) n = n.toFixed(decimals);
        let plusMinus = (addPlusIfPositive && n > 0) ? '+' : '';
        if (addPlusIfPositive && n < 0) {
            plusMinus = '-';
            n *= -1;
        }
        return `${plusMinus}${prefix}${n.toLocaleString()}`;
    }

    boolToYesNo(value) {
        if (typeof value === 'string') return value === 'true' ? this.tr('yes') : this.tr('no');
        return value ? this.tr('yes') : this.tr('no'); 
    }
}
